import React from 'react';
import { Grid, GridCol, GridRow, Heading, Image } from '@legalshield/adonis-ux-framework';
import PPLSIPartnerLogo from '../../../assets/ls-partner-logo.svg';
import PPLSIPeopleLogo from '../../../assets/pplsipeople-logo.svg';
import PPLSIMembersLogo from '../../../assets/ls-ids-logo.png';

const PartnerSupport: React.FC = () => {
  return (
    <>
      <Grid>
        <GridRow>
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_PARTNER_HEADER} />
            <img className="mr-4" src={PPLSIPartnerLogo} alt="shield-icon" />
            <p>{string_table.SUPPORT_PARTNER}</p>
          </GridCol>
        </GridRow>
      </Grid>
    </>
  );
};

export default PartnerSupport;
