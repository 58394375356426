import React from 'react';
import { Grid, GridCol, GridRow, Heading, Image } from '@legalshield/adonis-ux-framework';
import PPLSIPartnerLogo from '../../../assets/ls-partner-logo.svg';
import PPLSIPeopleLogo from '../../../assets/pplsipeople-logo.svg';
import PPLSIMembersLogo from '../../../assets/ls-ids-logo.png';

const Home: React.FC = () => {
  return (
    <>
      <Grid>
        <GridRow variant="half">
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_GROUPS_HEADER} />
            <Image alt="This is a image" classNames={['img-responsive']} src={PPLSIMembersLogo} />
            <p>{string_table.SUPPORT_GROUPS}</p>
          </GridCol>
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_MEMBER_HEADER} />
            <img className="mr-4" src={PPLSIMembersLogo} alt="shield-icon" />
            <p>{string_table.SUPPORT_MEMBER}</p>
          </GridCol>
        </GridRow>
        <GridRow variant="half">
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_PARTNER_HEADER} />
            <img className="mr-4" src={PPLSIPartnerLogo} alt="shield-icon" />
            <p>{string_table.SUPPORT_PARTNER}</p>
          </GridCol>
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_PEOPLE_HEADER} />
            <img className="mr-4" src={PPLSIPeopleLogo} alt="shield-icon" />
            <p>{string_table.SUPPORT_PEOPLE}</p>
          </GridCol>
        </GridRow>
      </Grid>
    </>
  );
};

export default Home;
