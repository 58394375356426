import React from 'react';
import { Grid, GridCol, GridRow, Heading, Image } from '@legalshield/adonis-ux-framework';
import PPLSIPartnerLogo from '../../../assets/ls-partner-logo.svg';
import PPLSIPeopleLogo from '../../../assets/pplsipeople-logo.svg';
import PPLSIMembersLogo from '../../../assets/ls-ids-logo.png';

const GroupSupport: React.FC = () => {
  return (
    <>
      <Grid>
        <GridRow>
          <GridCol>
            <Heading as="T20" text={string_table.SUPPORT_GROUPS_HEADER} />
            <Image alt="This is a image" classNames={['img-responsive']} src={PPLSIMembersLogo} />
            <p>{string_table.SUPPORT_GROUPS}</p>
          </GridCol>
        </GridRow>
      </Grid>
    </>
  );
};

export default GroupSupport;
