import { AnalyticServiceVendor, AnalyticsOptions, ThemisAnalytics } from '@legalshield/frontend-analytics';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { App } from './components/App/App';
import { ProfileContextProvider } from './contexts/profileContext';
import envConfig from './config';

const root = document.getElementById('root');

let renderMethod;
if (root && root.innerHTML !== '') {
  renderMethod = hydrate;
} else {
  renderMethod = render;
}

const analyticsConfig: AnalyticsOptions = {
  client: AnalyticServiceVendor.GoogleTagManager,
  config: {
    gtmService: envConfig.getGtmVars(),
  },
};
const themisAnalytics = new ThemisAnalytics(analyticsConfig);
export default themisAnalytics;

themisAnalytics.initialize();
renderMethod(
  <React.StrictMode>
    <ProfileContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProfileContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
