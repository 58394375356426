import { TagManagerArgs } from '@legalshield/frontend-analytics';

const getGtmVars = (): TagManagerArgs => {
  const host = window.location.hostname;
  if (host.includes('dev-') || host.includes('localhost')) {
    return {
      auth: '8ORNwoYVVPtSAsoW7UGgKw',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-17',
    };
  } else if (host.includes('uat-')) {
    return {
      auth: '5B6USoiYGGAjfirnUbMYCw',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-21',
    };
  } else {
    return {
      auth: 'g_gdq8mpXSg8PHfXMX3lQ',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-1',
    };
  }
};

export default {
  getGtmVars,
};
