import Cookies from 'js-cookie';

export const getInfoUser: any = () => {
  return JSON.parse(decodeURIComponent(Cookies.get('info_user') || '{}'));
};

export const checkStatus: any = (res: any) => {
  if (res.ok) {
    return res;
  }
  return res.json().then((res: any) => {
    return new Error(res.message);
  });
};
