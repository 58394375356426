import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import {
  AlertControl,
  Footer,
  Layout,
  Navigation,
  NavigationItem,
  useViewportSize,
} from '@legalshield/adonis-ux-framework';
import Home from '../Home/Home';
import PartnerSupport from '../PartnerSupport/PartnerSupport';
import GroupSupport from '../GroupSupport/GroupSupport';
import MemberSupport from '../MemberSupport/MemberSupport';
import PeopleSupport from '../PeopleSupport/PeopleSupport';
const offRealmSubDomains = ['groups', 'tempbenefits', 'w3'];
const groupSubDomains = ['groups', 'tempbenefits', 'w3'];
const memberSubDomains = [];
const peopleSubDomains = [];
const providerSubDomains = [];
const environmentIndicators = ['dev', 'uat'];

export const App: React.FC = () => {
  // Needed for routing the navigation
  const history = useHistory();

  const determineRoute = (domainHostName) => {
    const offRealmSubDomains = ['groups', 'tempbenefits', 'w3'];
    const groupSubDomains = ['groups', 'tempbenefits', 'w3'];
    const memberSubDomains = [];
    const peopleSubDomains = [];
    const providerSubDomains = [];
    const environmentIndicators = ['dev', 'uat'];
    const GROUP_ROUTE = '/group';
    const MEMBER_ROUTE = '/member';
    const INTERNAL_ROUTE = '/internal';
    const PARTNER_ROUTE = '/partner';
    const ROOT_ROUTE = '/';
    let determinedRoute = ROOT_ROUTE;
    const subdomain = domainHostName.split('.').shift();
    if (offRealmSubDomains.includes(subdomain)) {
      if (groupSubDomains.includes(subdomain)) {
        determinedRoute = GROUP_ROUTE;
      } else if (memberSubDomains.includes(subdomain)) {
        determinedRoute = MEMBER_ROUTE;
      } else if (peopleSubDomains.includes(subdomain)) {
        determinedRoute = INTERNAL_ROUTE;
      } else if (providerSubDomains.includes(subdomain)) {
        determinedRoute = PARTNER_ROUTE;
      } else {
        determinedRoute = ROOT_ROUTE;
      }
    } else {
      const secondLevelDomain =
        domainHostName.split('.').length > 2 ? domainHostName.split('.').slice(1).join('.') : domainHostName;
      const firstMarker = secondLevelDomain.split('-').shift();
      const baseDomain = environmentIndicators.includes(firstMarker)
        ? secondLevelDomain.split('-').slice(1).join('-')
        : secondLevelDomain;
      const memberDomains = [
        'damesdejustice.com',
        'idshield.ca',
        'idshield.com',
        'ladiesofjustice.com',
        'legalshield.com',
        'legalshield.ca',
        'noussommeslegalshield.com',
        'somoslegalshield.com',
        'wearelegalshield.ca',
        'wearelegalshield.com',
      ];
      const partnerDomains = ['legalshieldproviders.com', 'pplsipartners.com'];
      const groupDomains = ['legalshieldatwork.com', 'shieldatwork.com', 'shieldbenefits.com', 'shield-service.com'];
      const internalDomains = ['legalshieldinternal.com', 'pplsipeople.com'];
      if (memberDomains.includes(baseDomain)) {
        determinedRoute = MEMBER_ROUTE;
      } else if (partnerDomains.includes(baseDomain)) {
        determinedRoute = PARTNER_ROUTE;
      } else if (groupDomains.includes(baseDomain)) {
        determinedRoute = GROUP_ROUTE;
      } else if (internalDomains.includes(baseDomain)) {
        determinedRoute = INTERNAL_ROUTE;
      } else {
        determinedRoute = ROOT_ROUTE;
      }
    }
    return determinedRoute;
  };

  const setPage = () => {
    const hostName = window.location.origin;
    const referrer = document.referrer;
    const domainObj = referrer !== '' ? new URL(referrer) : new URL(hostName);
    const domainHostName = domainObj.hostname ? domainObj.hostname : '';
    const route = determineRoute(domainHostName);
    history.push(route);
  };

  useEffect(
    setPage, // <- function that will run on every dependency update
    [], // <-- empty dependency array
  );

  // Track our location
  const location = useLocation();
  const currentPath = location.pathname;

  const [isOpen, setIsOpen] = useState(false);

  // Called when the slideout is opened (the hamburger is clicked)
  // State that indicates the slideout is open
  const openClicked = () => {
    setIsOpen(true);
  };

  // Called when the slideout is closed
  const closeClicked = () => {
    setIsOpen(false);
  };

  // Configure the "hook" into the hamburger item in the header/design service
  useEffect(() => {
    if (currentPath === '/') {
      _lsh_set_hb_callback(openClicked);
    } else {
      _lsh_set_hb_callback(null);
    }
  });

  // Callback when a navigation item is clicked
  const navigationClicked = (index: number, data: string) => {
    history.push(data);
  };

  // This is the main navigation menu
  const navigationItems = [
    { data: '/', iconName: 'nav_home', id: 0, name: string_table.APP_NAVHOME },
    { data: '/member', iconName: 'action_eye_open', id: 1, name: string_table.APP_NAVMEMBER },
    { data: '/group', iconName: 'action_edit', id: 2, name: string_table.APP_NAVGROUP },
    { data: '/partner', iconName: 'file_document', id: 3, name: string_table.APP_NAVPARTNER },
    { data: '/internal', iconName: 'object_graph', id: 4, name: string_table.APP_NAVPEOPLE },
  ];

  // Watch for the screen smaller than 1024; this is used to enable/disable the hamburger menu and slideout
  const { width } = useViewportSize();
  const breakpoint = 1023;

  // The core page use our main layout component, containing our navigation.
  // This is largely boilerplate
  // Based on the route, the right content is shown
  return (
    <Layout
      variant="scroll"
      navigation={
        <Navigation
          items={navigationItems as NavigationItem[]}
          activeData={location.pathname}
          onClick={navigationClicked}
          classNames={['lsux-navigation']}
          navigationType={currentPath === '/' && width > breakpoint ? 'pane' : 'slideout'}
          isOpen={isOpen}
          onClose={closeClicked}
        />
      }
    >
      <AlertControl market={market} />
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/member" component={MemberSupport} />
          <Route path="/group" component={GroupSupport} />
          <Route path="/partner" component={PartnerSupport} />
          <Route path="/internal" component={PeopleSupport} />
        </Switch>
      </div>
      <Footer classNames={['lsux-layout-padding']} htmlContent={footerHtml} />
    </Layout>
  );
};
